<template>
    <div class="timer-container" @click="$router.push(link)">
        <div class="header-timer">
            {{ $t(`widget.${title}`).toUpperCase() }}
            <button v-if="existsClicAction != -1" @click.stop="changeRoute">
                <img :src="cssImage + 'add-icon.svg'" alt="trash" width="69%" />
            </button>
        </div>
        <template v-if="isOffline">
            <div class="offline">
                <img :src="cssImage + 'offline/no_wifi.svg'" alt="offline" width="15%" />
            </div>
        </template>
        <template v-else>
            <CardOperationalTimers v-if="title == 'timer_op_title'" :details="details" />

            <CardProducts v-if="title == 'productos_title'" :details="details" />
        </template>
    </div>
</template>

<script>
import CardOperationalTimers from './CardOperationalTimers.vue'
import CardProducts from './CardProducts.vue'
export default {
    name: 'WidgetBig',
    components: { CardOperationalTimers, CardProducts },
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cssImage: css_image_url
        }
    },
    computed: {
        isOffline() {
            return !fnCheckConnection()
        },
        link() {
            return this.details.params.find((item) => item.type === 'link').value
        },
        title() {
            return this.details.text.find((obj) => Object.keys(obj).includes('title')).title
        },
        existsClicAction() {
            return this.details.params.findIndex((param) => param.type === 'clic_to_action')
        },
        applyQuery() {
            const jsonString = this.details.params[this.existsClicAction].query.replace(/'/g, '"')
            return JSON.parse(jsonString)
        }
    },
    methods: {
        changeRoute() {
            if (this.existsClicAction != -1) {
                this.$router.push({
                    name: this.details.params[this.existsClicAction].value,
                    query: this.details.params[this.existsClicAction].query ? this.applyQuery : null
                })
            } else {
                this.$router.push(this.link)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.timer-container {
    width: 100%;
    min-width: 700px;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
}

.header-timer {
    background-color: #e0e0e0;
    padding: 17px 0px;
    text-align: center;
    font-size: 18px;
    font-family: $title-bold;
    position: relative;
}

.header-timer button {
    background-color: #1ba844;
    padding: 7px;
    border-radius: 4px;
    position: absolute;
    right: 17px;
    bottom: 8px;
    width: 37px;
    height: 37px;
}

.timer-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    gap: 5px;
}

.bg-success {
    background-color: #157d82;
    padding: 25px;
}

.bg-error {
    background-color: #bd1f1e;
    padding: 25px;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    position: relative;
    border: 8px solid white;
}

.count {
    font-size: 28px;
    color: #ffffff;
    font-family: $title-bold;
}

.pending-text {
    font-size: 20px;
    line-height: 32px;
    color: white;
    font-family: $title-bold;
}

.container-list {
    padding: 10px;
}

.timer-list {
    padding: 0px;
    min-height: 155px;
    max-height: 240px;
    overflow-y: scroll;
}

#timer-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e2e2e2;
}

#timer-list::-webkit-scrollbar {
    width: 9px;
}

#timer-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b3b3b3;
}

.timer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
    border-bottom: 1px solid #ddd;
}

.task {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    padding-right: 15px;
}

.task-description {
    padding-left: 10px;
    font-family: $text-light;
}

.time {
    font-size: 19px;
    color: #333;
    font-family: $title-bold;
}
.seconds {
    font-size: 16px;
}
.offline {
    padding: 150px 0px !important;
    display: flex;
    justify-content: center;
}

@media (max-width: 900px) {
    .task {
        font-size: 18px;
    }
}
</style>
