<template>
    <div v-if="existAlert" class="card-products alerts">
        <div class="item delete" :class="{ inactive: timerproduct['to_delete'] == 0 }">
            <div class="icon">
                <img :src="cssImage + 'trash.svg'" alt="trash" width="79%" />
            </div>
            <div class="alert">
                <span class="number">{{ timerproduct['to_delete'] }}</span>
                <span class="label">{{ $t(`widget.product_to_be_discarded`).toUpperCase() }}</span>
            </div>
        </div>
        <div class="item unknown" :class="{ inactive: timerproduct['without_timer'] == 0 }">
            <div class="icon">
                <img :src="cssImage + 'clock-circular-outline.svg'" alt="clock" width="70%" />
            </div>
            <div class="alert">
                <span class="number">{{ timerproduct['without_timer'] }}</span>
                <span class="label">{{ $t(`widget.without_timers`) }}</span>
            </div>
        </div>
    </div>
    <div v-else class="card-products empty">
        <img :src="cssImage + 'andy-happy.svg'" alt="andy-happy" width="20%" />
        <label class="without-alerts">{{ $t(`widget.without_alerts`).toUpperCase() }}</label>
    </div>
</template>

<script>
export default {
    name: 'CardProductTimers',
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cssImage: css_image_url
        }
    },
    computed: {
        timerproduct() {
            return this.$store.getters['timerproduct/getHomeWidgetData']
        },
        existAlert() {
            return this.timerproduct['to_delete'] > 0 || this.timerproduct['without_timer'] > 0
        }
    },
    methods: {
        count(param) {
            let store = this.$store.getters['timerproduct/getHomeWidgetData']

            return store[param]
        }
    }
}
</script>

<style lang="scss" scoped>
.card-products {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    align-items: unset;
    width: 100%;

    &.empty {
        align-content: space-around;
        flex-wrap: wrap;
    }
    .item {
        display: flex;
        height: 50%;

        .icon {
            background-color: $color-error-500;
            padding: 0px 12px;
            display: flex;
            justify-content: center;
            border-radius: 4px;
            width: 100px;
        }
        .alert {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            padding-left: 16px;

            .number {
                color: $color-neutral-600;
                color: $color-error-500;
                font-size: 65px;
                line-height: 65px;
                font-family: $title-bold;
            }
            .label {
                color: $color-neutral-600;
                @include font-size(m);
                font-family: $text-light;
                text-transform: uppercase;
            }
        }

        &.inactive {
            .icon {
                background-color: $color-neutral-400;
            }
            .alert {
                .number {
                    color: $color-neutral-400;
                }
            }
        }
    }
    .without-alerts {
        font-size: 18px;
        font-weight: 600;
        color: #2e2d2d;
    }
}
</style>
