<template>
    <div v-if="!isOffline" class="box" style="cursor: pointer" @click="$router.push(link)">
        <div v-if="type === 'icon'" class="count-active">
            <img :src="cssImage + 'timer.svg'" alt="box-icon" />
            <label>{{ totalTimerProduct }}</label>
        </div>
        <div v-else class="products-discard" :class="{ inactive: totalTimerProduct == 0 }">
            {{ totalTimerProduct }}
        </div>
        <div class="label">
            {{ $t(`widget.${details.id}`) }}
        </div>
    </div>
    <div v-else class="box">
        <img :src="cssImage + 'offline/no_wifi.svg'" />
    </div>
</template>

<script>
export default {
    name: 'SmallWidget',
    props: {
        details: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOnline: true,
            cssImage: css_image_url
        }
    },
    computed: {
        isOffline() {
            return !fnCheckConnection()
        },
        link() {
            return this.details.params.find((item) => item.type === 'link').value
        },
        totalTimerProduct() {
            const field = this.details.params.find((param) => param.type === 'value').value
            return this.$store.getters['timerproduct/getHomeWidgetData'][field]
        },
        type() {
            return this.details.params.find((param) => param.type === 'form').value
        }
    }
}
</script>

<style scoped lang="scss">
.box {
    cursor: pointer;
    width: 320px;
    height: 164px;
}
.products-discard {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid #bd1f1e;
    border-radius: 50%;
    margin: 0px;
    font-size: 24px;
    height: 80px;
    width: 80px;
    min-width: 80px;
    font-weight: 600;
    color: #222222;

    &.inactive {
        border-color: $color-neutral-300;
    }
}
</style>
